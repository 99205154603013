<template>
  <div>
    <!-- <div>banner图片</div> -->
    <div class="wrap-banner">
      <img
        class="banner"
        src="https://sms-1300492491.cos.ap-beijing.myqcloud.com/banners/banner.png"
      />
    </div>
    <div class="wrap-content">
      <van-row type="flex" justify="center">
        <van-col class="wrap-form" span="22">
          <van-row class="wrap-price" type="flex" justify="space-around">
            <van-col></van-col>
            <van-col span="4" :class="price==3?'red':'default'" @click="getprice(3)">3元</van-col>
            <van-col span="4" :class="price==6?'red':'default'" @click="getprice(6)">6元</van-col>
            <van-col span="4" :class="price==9?'red':'default'" @click="getprice(9)">9元</van-col>
            <van-col span="4" class="default" @click="rand">随机</van-col>
            <van-col></van-col>
          </van-row>
          <!-- <div>表单</div> -->
          <van-form @submit="onSubmit" ref="formData">
            <!-- <div>乐捐金额</div> -->
            <van-row class="content-form" type="flex" justify="space-between">
              <van-col class="left" offset="1" span="6">乐捐金额</van-col>
              <van-col class="right" span="14">
                <van-field v-model="price" :rules="[{ pattern, message: '请输入正确的金额' }]" />
              </van-col>
              <van-col span="1"></van-col>
            </van-row>
            <!-- <div>功德芳名</div> -->
            <van-row class="content-form" type="flex" justify="space-between">
              <van-col class="left" offset="1" span="6">功德芳名</van-col>
              <van-col class="right" span="14">
                <van-field v-model="name" placeholder="请输入姓名（选填）" />
              </van-col>
              <van-col span="1"></van-col>
            </van-row>
            <!-- <div>联系电话</div> -->
            <van-row class="content-form" type="flex" justify="space-between">
              <van-col class="left" offset="1" span="6">联系电话</van-col>
              <van-col class="right" span="14">
                <van-field v-model="phone" placeholder="请输入电话（选填）" />
              </van-col>
              <van-col span="1"></van-col>
            </van-row>
            <!-- <div>祈福心愿</div> -->
            <van-row class="content-form" type="flex" justify="space-between">
              <van-col class="left" offset="1" span="6">祈福心愿</van-col>
              <van-col class="wished" span="14">
                <div>
                  <van-field
                    v-model="wish"
                    rows="3"
                    autosize
                    type="textarea"
                    maxlength="50"
                    placeholder="请输入祈愿内容（选填）"
                    show-word-limit
                    label-align="right"
                    colon
                  />
                </div>
              </van-col>
              <van-col span="1"></van-col>
            </van-row>
          </van-form>
          <van-row type="flex" justify="center">
            <van-col span="9" class="btn">
              <van-button color="#bb5548" round size="large" @click="generate">乐捐</van-button>
            </van-col>
          </van-row>
          <van-row type="flex" justify="center">
            <van-col class="wrap-total">乐捐总计{{total}}元</van-col>
          </van-row>
        </van-col>
      </van-row>
      <!-- <div>底部地基</div> -->
      <div class="wrap-bottom"></div>
      <van-overlay :show="show" />
    </div>
  </div>
</template>
<script>
import { ready } from "@/utils/wxutils.js";
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      price: 3,
      name: "",
      phone: "",
      wish: "",
      total: "",
      username: "",
      message: "",
      show: false,
      pattern: /^((0{1}\.\d{1,2})|([1-9]\d*\.{1}\d{1,2})|([1-9]+\d*))$/,
    };
  },
  created: function () {
    this.totals();
    this.image;
    console.log(this.image);
  },
  methods: {
    //提交
    onSubmit() {
      this.show = true;
      let that = this;
      this.$api.daypay
        .generateorder(this.price, this.name, this.phone, this.wish)
        .then((res) => {
          console.log(res.data);
          if (res.data.status == 1) {
            ready(["chooseWXPay"])
              .then((res2) => {
                console.log(res2);
                wx.chooseWXPay({
                  timestamp: res.data.data.timestamp,
                  nonceStr: res.data.data.nonceStr,
                  package: res.data.data.package,
                  signType: res.data.data.signType,
                  paySign: res.data.data.paySign,
                  success: function () {
                    let orderno = res.data.data.orderno;
                    let retry = 1;
                    let S = setInterval(function () {
                      if (retry > 5) {
                        clearInterval(S);
                        that.$toast.fail("未获取到支付结果");
                        that.show = false;
                        return;
                      } //查询订单是否支付
                      that.$api.daypay.ensure(orderno).then((res) => {
                        retry += 1;
                        console.log(res);
                        if (res.data.status == 1) {
                          clearInterval(S);
                          that.totals();
                          that.show = false;
                          that.$toast.success("支付成功");
                          that.$router.push({
                            path: "/center/daypay",
                          });
                        }
                      });
                    }, 2000); //console.log(result);
                  },
                  cancel: function () {
                    that.show = false;
                  },
                });
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
    },
    //乐捐总数
    totals() {
      this.$api.daypay.gettotal().then((res) => {
        this.total = res.data.data.total;
        console.log(res.data.data.total);
      });
    },
    //随机金额
    rand() {
      this.price = Math.ceil(Math.random() * 100);
      console.log(this.price);
    },
    //获取金额
    getprice(price2) {
      this.price = price2;
    },
    //生成乐捐订单
    generate() {
      this.$refs.formData.submit();
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  &-price {
    margin-top: 1.4rem;
    margin-bottom: 1rem;
  }
  &-total {
    margin-top: -1.2rem;
    margin-bottom: 1.6rem;
    font-size: 16px;
    color: #c4a779;
  }
}
.default {
  color: #c89932;
  border: 1px solid #c89932;
  border-radius: 0.9rem;
  text-align: center;
  font-size: 18px;
}
.red {
  color: #bb5548;
  border: 1px solid #bb5548;
  border-radius: 0.9rem;
  text-align: center;
  font-size: 18px;
}
</style>

